<template>
  <c-exception type="500"/>
</template>

<script>
import exception from '~/components/exception'

export default {
  name: 'exception500',
  components: {
    cException: exception
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="less">
</style>
